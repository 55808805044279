import { defineComponent, computed } from '@vue/composition-api';
import CitiesTable from '@/Admin/components/CitiesTable.vue';
import VendorsTable from '@/Admin/components/VendorsTable.vue';
import BookersTable from '@/Admin/components/BookersTable.vue';
import HighlightedDashboard from '@/Admin/components/HighlightedDashboard.vue';
import FeedbacksTable from '@/Admin/components/FeedbacksTable.vue';
import BookingsPayout from '@/Admin/components/BookingsPayout.vue';
import SpacesTable from '@/Admin/components/SpacesTable.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { AdminRoutes } from '@/Admin/admin.const';
export default defineComponent({
    components: {
        BookersTable,
        CitiesTable,
        FeedbacksTable,
        HighlightedDashboard,
        BookingsPayout,
        SpacesTable,
        VendorsTable
    },
    setup(props, context) {
        const { root } = context;
        const TABS = [
            {
                name: 'vendors',
                title: root.$i18n.t('admin.vendor.tab_name').toString(),
                iconClass: 'fa-solid fa-house fa-sm'
            },
            {
                name: 'bookers',
                title: root.$i18n.t('admin.booker.tab_name').toString(),
                iconClass: 'fa-sharp fa-solid fa-magnifying-glass-location fa-sm'
            },
            {
                name: 'cities',
                title: root.$i18n.t('admin.city.tab_name').toString(),
                iconClass: 'fa-solid fa-city fa-sm'
            },
            {
                name: 'feedbacks',
                title: root.$i18n.t('admin.feedback.tab_name').toString(),
                iconClass: 'fa-solid fa-star fa-sm'
            },
            {
                name: 'highlighted',
                title: root.$i18n.t('admin.highlighted.tab_name').toString(),
                iconClass: 'fa-solid fa-fire-flame-curved fa-sm'
            },
            {
                name: 'payouts',
                title: root.$i18n.t('admin.payouts.tab_name').toString(),
                iconClass: 'fa-solid fa-receipt fa-sm'
            },
            {
                name: 'spaces',
                title: root.$i18n.t('admin.space.tab_name').toString(),
                iconClass: 'fa-solid fa-handshake fa-sm'
            }
        ];
        const urlTab = computed(() => root.$route.params.tab);
        const selectedTab = computed(() => urlTab.value || TABS[0].name);
        const onTabClick = (tabName) => {
            globalThis.$router.push(useGetLocalizedPath(`${AdminRoutes.ADMIN}/${tabName}`));
        };
        const goToVendor = (organisationName) => {
            onTabClick('vendors');
            setTimeout(() => {
                const tableRow = document.getElementById(organisationName);
                if (!tableRow)
                    return;
                root.$scrollTo(tableRow, 500, { offset: -100 });
                const row = tableRow?.closest('tr');
                if (!row)
                    return;
                row.classList.add('highlighted-row');
            }, 500);
        };
        return {
            selectedTab,
            TABS,
            goToVendor,
            onTabClick
        };
    }
});
